import { css } from '@emotion/react';

const dohopconnectFonts = css`
  @font-face {
    font-family: Montserrat-Regular;
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/DohopConnect/TTInterfaces-Regular.ttf');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Boogie School Sans';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/DohopConnect/TTInterfaces-Light.ttf');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Boogie School Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/DohopConnect/TTInterfaces-Regular.ttf');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Boogie School Sans';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/DohopConnect/TTInterfaces-Medium.ttf');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Boogie School Sans';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/DohopConnect/TTInterfaces-SemiBold.ttf');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Boogie School Sans';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/DohopConnect/TTInterfaces-Bold.ttf');
  }

  @font-face {
    font-display: fallback;
    font-family: Interfaces;
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/DohopConnect/TTInterfaces-Light.ttf');
  }

  @font-face {
    font-display: fallback;
    font-family: Interfaces;
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/DohopConnect/TTInterfaces-Regular.ttf');
  }

  @font-face {
    font-display: fallback;
    font-family: Interfaces;
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/DohopConnect/TTInterfaces-Medium.ttf');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Boogie School Sans';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/DohopConnect/TTInterfaces-SemiBold.ttf');
  }

  @font-face {
    font-display: fallback;
    font-family: Interfaces;
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/DohopConnect/TTInterfaces-Bold.ttf');
  }
`;

export default dohopconnectFonts;
